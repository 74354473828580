import styled from "styled-components";

export default function CompanysLogo() {
  return (
    <Container>
      <div>
        <Clients>Our Valuable Clients</Clients>
        <Line src="/images/line@2x.png" alt="line" />
      </div>
      <Logos src="/images/companies.png" alt="companies" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  /* margin: 200px 0px; */
  padding: var(--padding-xxl-container);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Logos = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;
`;

const Clients = styled.span`
  width: 15%;
  color: var(--Dark-Dark-01, #191638);
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`;

const Line = styled.img`
  max-width: 85%;
  height: 2px;
  flex-shrink: 0;
`;
