import styled from "styled-components";
import PortfolioDemo from "./PortfolioDemo";

const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: var(--padding-xxl-container);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xxl-container);
  background-color: var(--portfolio-bg);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  
  }
`;

const Banner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--padding-xxl-card);
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card);
  background-color: white;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const MiniTitle = styled.div`
  color: var(--green-color);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;

const Title = styled.div`
  width: 624px;
  color: var(--Dark-Dark-02, #3b3663);
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: 54px;
  }
`;

const Button = styled.button`
  display: flex;
  height: 64px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--green-deep);
  color: var(--Dark-Dark-07, #fbfaff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1.3px;
  line-height: 32px; /* 160% */
  border: none;
  cursor: pointer;
`;

export default function Portfolio() {
  return (
    <Container id="portfolio">
      <Banner>
        <TitleContainer>
          <MiniTitle> Portfolio </MiniTitle>
          <Title>
            See Our Awesome <span>Portfolio</span>
          </Title>
        </TitleContainer>
        <Button>View All Product</Button>
      </Banner>
      <PortfolioDemo />
    </Container>
  );
}
