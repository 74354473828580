import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaLocationArrow, FaPhone } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { Link as ScrollLink } from "react-scroll";

const Container = styled.div`
  width: 100%;
  height: calc(740px + 120px);
  flex-shrink: 0;
  position: relative;
  background-color: var(--footer-bg);
`;

const Wrapper = styled.div`
  width: 100%;
  height: 740px;
  flex-shrink: 0;
  background-color: var(--green-deep);
  padding: 0 20rem;
  padding-top: 15rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const SearchSection = styled.div`
  width: 70%;
  left: 15%;
  height: 245px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--green-deep);
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`;

const TextSection = styled.div`
  display: flex;
  width: 445px;
  height: 125px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 15px;
  flex-shrink: 0;
`;

const HeaderText = styled.div`
  color: var(--white, #fff);
  font-family: Work Sans;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 142.105% */
`;

const SubText = styled.div`
  color: var(--Dark-dark_6, #f7f5ff);
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
`;

const SearchArea = styled.form`
  width: 445px;
  height: 82px;
  flex-shrink: 0;
  border-radius: 16px;
  padding: 0 10px;
  background: var(--white, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchField = styled.input`
  height: 50%;
  width: 60%;
  border: none;
  padding: 0 10px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.3px;
  outline: none;
  &::placeholder {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1.3px;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  width: 142px;
  height: 52px;
  padding: 17px 20px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--green-deep);
  color: var(--white, #fff);
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.3px;
  border: none;
  cursor: pointer;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 15px;
`;

const Logo = styled.img`
  height: 30px;
  width: auto;
`;

const Title = styled.div`
  color: var(--white, #fff);
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  a {
    color: #eae0e0;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-decoration: none;
    line-height: 24px; /* 171.429% */
    cursor: pointer;
  }
`;

export default function Footer() {
  return (
    <Container>
      <SearchSection>
        <TextSection>
          <HeaderText>Newsletter Subscribe</HeaderText>
          <SubText>
            Aute irure dolor in reprehenderit in voluptate at esse cillum dolore
            eu fugiat pariatur.
          </SubText>
        </TextSection>
        <SearchArea>
          <SearchField placeholder="enter your email" />
          <SubmitButton type="submit">Subscribe</SubmitButton>
        </SearchArea>
      </SearchSection>
      <Wrapper>
        <Items>
          <Logo src="/images/logo.png" alt="logo" />
          <Item>
            <FaLocationArrow fontSize={16} color="white" />
            <Link to="/">New York, NY 535022</Link>
          </Item>
          <Item>
            <FaLocationArrow fontSize={16} color="white" />
            <Link to="/">Dhanmondi Dhaka Bangladesh</Link>
          </Item>
          <Item>
            <FaPhone fontSize={16} color="white" />
            <Link to="/">+880-1831679008</Link>
          </Item>
          <Item>
            <FaPhone fontSize={16} color="white" />
            <Link to="/">+880-1999-982971</Link>
          </Item>
          <Item>
            <LuMail fontSize={16} color="white" />
            <Link to="/">easysoftcode.esc@gmail.com</Link>
          </Item>
        </Items>
        <Items>
          <Title>Services</Title>
          <Item>
            <Link to="/">Design</Link>
          </Item>
          <Item>
            <Link to="/">Development</Link>
          </Item>
          <Item>
            <Link to="/">Search Engine Optimization (SEO)</Link>
          </Item>
          <Item>
            <Link to="/">Installation</Link>
          </Item>
          <Item>
            <Link to="/">Digital Marketing</Link>
          </Item>
        </Items>
        <Items>
          <Title>Compnay</Title>
          <Item>
            <Link to="/">About Company</Link>
          </Item>
          <Item>
            <Link to="/">Support</Link>
          </Item>
          <Item>
            <Link to="/">Privacy Policy</Link>
          </Item>
          <Item>
            <Link to="/">Product Sourcing</Link>
          </Item>
          <Item>
            <Link to="/">Terms and Conditions</Link>
          </Item>
        </Items>
        <Items>
          <Title>Quick Links</Title>
          <Item>
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              Home
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              About
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Services
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="products"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Products
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="pricing"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Pricing
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="portfolio"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Portfolio
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="team"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Team
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="testimonials"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Testimonials
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="blog"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              News
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              to="blog"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Blog
            </ScrollLink>
          </Item>

          <Item>
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              Contact
            </ScrollLink>
          </Item>
        </Items>
      </Wrapper>
    </Container>
  );
}
