import styled from "styled-components";
import ratingCardData from "./data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RatingCard from "./RatingCard";
import { useEffect, useState } from "react";
import "./carousel.css";

const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: var(--padding-xxl-container);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xxl-container);
  background-color: var(--testimonial-bg);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  
  }
`;

const TextContainer = styled.div`
  height: 161px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-text);
`;

const MiniTitle = styled.div`
  color: var(--green-color);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;

const Title = styled.div`
  color: var(--Dark-Dark-02, #3b3663);
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
  }
`;

const SubTitle = styled.div`
  width: 540px;
  color: var(--Dark-Dark-03, #7a7792);
  font-family: Work Sans;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-desc);
  line-height: 28px;
`;

const RatingCardContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  height: 560px;
  margin: 0 auto;
  overflow: hidden;
`;

export default function Testimonials() {
  const [responseData, setResponseData] = useState([]);
  useEffect(() => {
    setResponseData(ratingCardData);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Container id="testimonials">
      <TextContainer>
        <MiniTitle>Testimonial</MiniTitle>
        <Title>
          What They are <span>Saying About Us</span>
        </Title>
        <SubTitle>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </SubTitle>
      </TextContainer>
      <RatingCardContainer>
        <Carousel
          responsive={responsive}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          infinite={true}
        >
          {responseData &&
            responseData.length > 0 &&
            responseData.map((item) => (
              <RatingCard
                context={{
                  id: item.id,
                  name: item.name,
                  rating: item.rating,
                  comment: item.comment,
                  profileImg: item.profileImg,
                }}
              />
            ))}
        </Carousel>
      </RatingCardContainer>
    </Container>
  );
}
