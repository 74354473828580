import styled from "styled-components";
import PriceCard from "./PriceCard";

const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: var(--padding-xxl-container);
  flex-direction: column;
  align-items: center;
  background-color: var(--pricingPlan-bg);
  gap: var(--gap-xxl-container);
  @media only screen and (max-width: 1920px) {
    padding: 3rem 5rem;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-text);
`;

const MiniTitle = styled.div`
  color: var(--green-color);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;

const Title = styled.div`
  width: 730px;
  color: var(--Dark-Dark-02, #3b3663);
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: 54px;
  }
`;

const SubTitle = styled.div`
  width: 540px;
  color: var(--Dark-Dark-03, #7a7792);
  font-family: Work Sans;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: var(--letter-spacing-desc);
`;

const Tabs = styled.div`
  width: 220px;
  height: 44px;
  border-radius: 22px;
  background: var(--Dark-Dark-05, #eae8f3);
  display: flex;
  align-items: center;
`;

const MonthlyBtn = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 111px;
  height: 44px;
  border-radius: 22px;
  background: var(--green-deep);
  color: var(--Dark-Dark-07, #fbfaff);
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px; /* 183.333% */
  letter-spacing: 1.2px;
`;

const YearlyBtn = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Dark-Dark-03, #7a7792);
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 22px; /* 183.333% */
  width: 111px;
  height: 44px;
  border-radius: 22px;
`;

const PriceTable = styled.div`
  width: 100%;
  flex-shrink: 0;
  background: transparent;
  /* padding: 2rem; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default function PricingPlan() {
  return (
    <Container id="pricing">
      <TextContainer>
        <MiniTitle>SUBSCRIPTION PRICING</MiniTitle>
        <Title>
          Our <span>Pricing Plan</span>
        </Title>
        <SubTitle>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </SubTitle>
      </TextContainer>
      <Tabs>
        <MonthlyBtn>MONTHLY</MonthlyBtn>
        <YearlyBtn>YEARLY</YearlyBtn>
      </Tabs>
      <PriceTable>
        <PriceCard context={{ price: 0, title: "Intro", time: "month" }} />
        <PriceCard
          context={{ price: 250, title: "Starter Plan", time: "month" }}
        />
        <PriceCard
          context={{ price: 300, title: "Business Plan", time: "month" }}
        />
        <PriceCard
          context={{ price: 450, title: "Ultimate Plan", time: "month" }}
        />
      </PriceTable>
    </Container>
  );
}
