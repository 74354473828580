import styled from "styled-components";

const Card = styled.div`
  min-height: 525.128px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border: 2px solid var(--green-white);
  border-radius: 10px;
  background-color: white;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  &:hover {
    transform: translateY(-40px);
    flex-shrink: 0;
    border-radius: 28.444px;
    background: var(
      --Gradiant-02,
      linear-gradient(
        180deg,
        rgb(15, 161, 86, 100%) 0%,
        rgb(23 186 19 / 100%) 100%
      )
    );
    box-shadow: 0px 45.949px 37.197px 0px rgba(82, 67, 194, 0.3);
    color: white !important;
    p,
    span,
    h3,
    h2,
    div {
      color: white !important;
    }
    .check-icon {
      background-color: white;
      border-radius: 50%;
    }
    button {
      background-color: white;
      color: var(--green-deep);
    }
  }
`;

const Price = styled.h2`
  color: var(--Dark-Dark-01, #191638);
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 112.5% */
  span {
    color: var(--Dark-Dark-03, #7a7792);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
`;

const Title = styled.h3`
  color: var(--Dark-Dark-01, #191638);
  font-family: Work Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
`;

const Description = styled.p`
  color: var(--Dark-Dark-03, #7a7792);
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: var(--letter-spacing-desc);
`;

const Option = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  gap: 10.94px;

  span:nth-child(1) {
    width: 21px;
    height: auto;
    flex-shrink: 0;
  }
  span:nth-child(2) {
    width: 100%;
    flex-shrink: 0;
    color: var(--Dark-Dark-03, #7a7792);
    font-family: Poppins;
    letter-spacing: var(--letter-spacing-desc);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
`;

const Button = styled.button`
  width: 100%;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--green-deep);
  color: var(--Dark-Dark-07, #fbfaff);
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1.3px;
  line-height: 26px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

export default function PriceCard(props) {
  const { price, title, time } = props.context;

  const checkImg = (
    <svg
      className="check-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M11.7009 0.820801C5.65876 0.820801 0.760681 5.71888 0.760681 11.761C0.760681 17.8031 5.65876 22.7011 11.7009 22.7011C17.7429 22.7011 22.641 17.8031 22.641 11.761C22.634 5.7218 17.74 0.827834 11.7009 0.820801Z"
        fill="#5243C2"
        fill-opacity="0.103693"
      />
      <path
        d="M18.016 8.29616L11.7756 16.7648C11.6268 16.9622 11.4048 17.0916 11.1597 17.1238C10.9145 17.1561 10.6667 17.0884 10.4719 16.9362L6.01558 13.3733C5.62234 13.0586 5.55867 12.4847 5.87336 12.0915C6.18805 11.6983 6.76194 11.6346 7.15518 11.9493L10.8712 14.9223L16.5482 7.21765C16.7344 6.9383 17.059 6.78385 17.3932 6.81563C17.7274 6.84741 18.0171 7.06026 18.1472 7.36969C18.2774 7.67912 18.227 8.03505 18.016 8.29616Z"
        fill="#5243C2"
      />
    </svg>
  );

  return (
    <Card>
      <Price>
        ${price}
        {price && <span>/{time}</span>}
      </Price>
      <Title>{title}</Title>
      <Description>
        For most businesses that want to otpimize web queries
      </Description>
      <Option>
        <span>{checkImg}</span>
        <span>All limited links</span>
      </Option>
      <Option>
        <span>{checkImg}</span>
        <span>Own analytics platform</span>
      </Option>
      <Option>
        <span>{checkImg}</span>
        <span>Chat support</span>
      </Option>
      <Option>
        <span>{checkImg}</span>
        <span>Optimize hashtags</span>
      </Option>
      <Option>
        <span>{checkImg}</span>
        <span>Unlimited users</span>
      </Option>
      <Button>Choose plan</Button>
    </Card>
  );
}
