import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BlogCard from "./BlogCard";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: var(--padding-xxl-container);
  flex-direction: column;
  align-items: center;
  background-color: var(--newsAndBlog-bg);
  gap: var(--gap-xxl-container);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  }
`;

const TextSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-text);
`;

const MiniTitle = styled.div`
  color: var(--green-color);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;

const Title = styled.div`
  color: var(--Dark-Dark-02, #3b3663);
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
  }
`;

const Description = styled.div`
  width: 540px;
  color: var(--Dark-Dark-03, #7a7792);
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-desc);
  line-height: 28px; /* 155.556% */
`;

const CardsSection = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 650px;
`;

export default function NewsAndBlog() {
  return (
    <Container id="blog">
      <TextSection>
        <MiniTitle>News & Blog</MiniTitle>
        <Title>
          Our Latest <span>News & Blog</span>
        </Title>
        <Description>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </Description>
      </TextSection>
      <CardsSection>
        <Carousel
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3, // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2, // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1, // optional, default to 1.
            },
          }}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed={6000}
          infinite={true}
        >
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
        </Carousel>
      </CardsSection>
    </Container>
  );
}
