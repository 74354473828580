import { styled } from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 900px;
  flex-shrink: 0;
  border-radius: 0px 0px 0px 300px;
  background-color: var(--green-deep);
  padding: var(--padding-xxl-container);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1600px) {
    padding: var(--padding-xl-container);
  }
`;

// const Box = styled.div`
//   margin: 100px 0px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

const TextSection = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  div:nth-child(1) {
    color: var(--Dark-Dark-07, #fbfaff);
    /* Text/tag */
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.26px;
    text-transform: uppercase;
  }

  div:nth-child(2) {
    width: 623.944px;
    color: var(--Dark-Dark-05, #eae8f3);
    font-family: Work Sans;
    font-size: var(--h1-size);
    font-style: normal;
    font-weight: 900;
    line-height: 72px; /* 128.571% */
    @media only screen and (max-width: 1600px) {
      font-size: var(--h1-size);
      b {
        font-size: var(--h1-size) !important;
      }
    }
    b {
      color: black;
      font-family: Work Sans;
      font-size: 56px;
      font-style: normal;
      font-weight: 900;
      line-height: 72px;
    }
  }

  div:nth-child(3) {
    width: 570.101px;
    color: var(--Dark-Dark-05, #eae8f3);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  button {
    display: flex;
    width: 191px;
    height: 64px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--Dark-Dark-07, #fbfaff);
    border: none;
    cursor: pointer;
  }

  button:nth-child(1) {
    color: var(--Primary-main, #4154f1);
    /* Text/20/Medium */
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 32px; /* 160% */
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      background: none;
      border: 1px solid var(--Dark-Dark-07, #fbfaff);
      color: white;
    }
  }

  button:nth-child(2) {
    color: var(--Dark-Dark-07, #fbfaff);
    /* Text/20/Medium */
    background: none;
    border: 1px solid var(--Dark-Dark-07, #fbfaff);
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 32px; /* 160% */
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      background: var(--Dark-Dark-07, #fbfaff);
      color: black;
    }
  }
`;

const ImageSection = styled.div`
  width: 490.572px;
  height: 601px;
  flex-shrink: 0;
  position: relative;
  @media only screen and (max-width: 1600px) {
    width: 400px;
    height: 500px;
  }

  img:nth-child(1) {
    position: absolute;
    height: auto;
    width: 90%;
    top: 0;
    right: 0;
    z-index: 2;
    border-radius: 10px;
    background: var(--Text-Color-2, #858a8f);
    box-shadow: 0px 54px 50px 0px rgba(67, 67, 68, 0.07);
  }
`;

export default function Home() {
  return (
    <Container id="home">
      <TextSection>
        <div>Digital Agency</div>
        <div>
          We offer modern solutions for <b>growing your business</b>
        </div>
        <div>
          Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.
        </div>
        <Buttons>
          <button type="button">Get Started</button>
          <button type="button">See Portfolio</button>
        </Buttons>
      </TextSection>
      <ImageSection>
        <img src="/images/businessmen.png" alt="businessmen" />
      </ImageSection>
    </Container>
  );
}
