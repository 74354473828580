import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--text-input: 'Work Sans';
--font-font-awesome-5-brands: 'Font Awesome 5 Brands';
--text-20-medium: Poppins;
--paragraph-p1: Inter;

/* font sizes */
--text-input-size: 0.88rem;
--text-button-lg-size: 0.94rem;
--p2-size: 1rem;
--headline-h2-size: 2.38rem;
--font-size-smi: 0.81rem;
--font-size-6xl-3: 1.58rem;
--h5-size: 1.13rem;
--h3-size: 1.88rem;
--h2-size: 3rem;
--h1-size: 3.5rem;
--text-20-medium-size: 1.25rem;
--p4-size: 0.75rem;
--headline-h4-size: 1.5rem;

/* Colors */
--about-bg:rgb(15, 161, 86, 10%);
--services-bg: rgb(119, 120, 124, 10%);
--demo-bg: rgb(15, 161, 86, 10%);
--pricingPlan-bg: rgb(119, 120, 124, 10%);
--portfolio-bg:rgb(15, 161, 86, 10%);
--business-bg: rgb(119, 120, 124, 10%);
--testimonial-bg: rgb(15, 161, 86, 10%);
--team-bg: rgb(119, 120, 124, 10%);
--newsAndBlog-bg: rgb(15, 161, 86, 10%);
--footer-bg: rgb(15, 161, 86, 10%);

--green-color: rgb(23 186 19 / 100%);
--green-deep: rgb(15, 161, 86, 100%);
--green-white: rgb(15, 161, 86, 50%);

--primary-color-system-white: #fff;
--dark-dark-4: #aaa8bd;
--primary-main1: #8c7dff;
--primary-main: #4154f1;
--dark-dark-07: #fbfaff;
--dark-dark-6: #f7f5ff;
--primary-primary-shad-light: #f0eeff;
--dark-dark-5: #eae8f3;
--color-lightcoral: #ff8989;
--dark-dark-03: #7a7792;
--dark-dark-01: #191638;
--dark-dark-02: #3b3663;
--primary-main-light: #8d9aff;
--text-text-2: #434c56;
--text-text-1: #283039;
--text-text-3: #5b6571;
--status-danger: #ff5c5c;
--color-silver: #c2c2c2;
--primary-primary-color: #304ffe;

/* Gaps */
--gap-3xs: 0.63rem;
--gap-7xs-3: 0.33rem;
--gap-41xl: 3.75rem;
--gap-4xs-5: 0.53rem;
--gap-xs-5: 0.72rem;
--gap-xl: 1.25rem;
--gap-21xl: 2.5rem;
--gap-5xs: 0.5rem;
--gap-15xl-3: 2.14rem;
--gap-7xs-7: 0.36rem;
--gap-mid-1: 1.07rem;
--gap-xs-4: 0.71rem;
--gap-3xs-1: 0.57rem;
--gap-12xl: 1.94rem;

/* Paddings */
--padding-mid: 1.06rem;
--padding-xl: 1.25rem;
--padding-101xl: 7.5rem;  
--padding-301xl: 20rem;
--padding-base: 1rem;
--padding-5xl: 1.5rem;
--padding-3xs: 0.63rem;


--padding-xxl-container: 4rem 15rem; /* Assuming base font size is 16px */
--padding-xl-container: 4rem 10rem; /* Assuming base font size is 16px */
--padding-xxl-card: 1.4rem 3rem;
--padding-xl-card: 1.4rem 2rem;
--border-radius-card: .8rem;

--gap-xxl-card: 1rem;
--gap-xxl-container: 2rem;
--gap-text: 1rem;

--shadow-card: rgba(149, 157, 165, 0.2) 0px 8px 24px;

--letter-spacing-mini: 2px;
--letter-spacing-desc: 1.3px;


/* Border radiuses */
--br-7xs: 6px;
--br-base: 16px;
--br-6xs-7: 6.7px;
--br-105xl-5: 124.5px;
--br-5xs: 8px;
--br-131xl: 150px;
--br-9xl-4: 28.4px;
--br-3xl: 22px;
--br-lg-3: 18.3px;
--br-11xs: 2px;
--br-3xs: 10px;

}
`;
