import styled from "styled-components";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Card = styled.div`
  position: relative;
  height: 449px;
  border-radius: var(--border-radius-card);
  /* border: 2px solid var(--green-color); */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  &:hover {
    /* border: 2px solid var(--gradiant-04, #4154f1); */
    border-radius: 8px;
    .card-img {
      border-radius: 8px;
    }
    .social-items {
      display: flex;
    }
  }
`;

const CardImg = styled.div`
  width: 100%;
  height: 249px;
  border-radius: 16.5px 151.5px 21px 52px;
  background: url("/images/TeamImg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #d9d9d9;
  flex-shrink: 0;
`;

const Title = styled.h1`
  color: var(--green-color);
  text-align: center;

  /* H3 */
  font-family: Work Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 40px; /* 133.333% */
`;

const ProfessionTitle = styled.div`
  color: var(--green-deep);
  text-align: center;

  /* P3 */
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

const Description = styled.p`
  width: 252px;
  color: var(--Dark-Dark-03, #7a7792);
  text-align: center;

  /* P3 */
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

const SocialItems = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  width: 40px;
  top: 25%;
  left: -40px;
  background-color: white;
  /* border: 2px solid var(--green-deep); */
  border-radius: 4px;
  padding: 20px 0px;
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  a {
    transform: scale(1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export default function TeamCard() {
  return (
    <Card>
      <CardImg className="card-img" />
      <Title>Md. Sagor Jahan</Title>
      <ProfessionTitle>Software engineer & IT Marketing</ProfessionTitle>
      <Description>
        I am a person who is positive about every aspect of life.
      </Description>
      <SocialItems className="social-items">
        <Link to="/">
          <FaFacebook color="darkcyan" fontSize={22} />
        </Link>
        <Link to="/">
          <FaTwitter color="darkcyan" fontSize={22} />
        </Link>
        <Link to="/">
          <FaInstagram color="darkcyan" fontSize={22} />
        </Link>
        <Link to="/">
          <FaLinkedin color="darkcyan" fontSize={22} />
        </Link>
      </SocialItems>
    </Card>
  );
}
