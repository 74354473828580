import styled from "styled-components";
import GridElement from "./GridElement";

const Container = styled.div`
  width: 100vw;
  padding: var(--padding-xxl-container);
  background: var(--services-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xxl-container);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  }
`;

const Textarea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-text);
`;

const Typography1 = styled.div`
  color: var(--green-color);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;
const Typography2 = styled.div`
  width: 730px;
  color: var(--Dark-Dark-02, #3b3663);
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
  }
`;
const Typography3 = styled.div`
  width: 540px;
  color: var(--Dark-Dark-03, #7a7792);
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: var(--letter-spacing-desc);
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 20px;
  
  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

// const GridItems = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   gap: 31px;
// `;

export default function Services() {
  return (
    <Container id="services">
      <Textarea>
        <Typography1>Services</Typography1>
        <Typography2>
          Services we can <span>help you with</span>
        </Typography2>
        <Typography3>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </Typography3>
      </Textarea>
      <Grid>
        <GridElement
          context={{
            imagePath: "/images/design.png",
            title: "Design",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />
        <GridElement
          context={{
            imagePath: "/images/development.png",
            title: "Development",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />
        <GridElement
          context={{
            imagePath: "/images/seo.png",
            title: "SEO",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />

        <GridElement
          context={{
            imagePath: "/images/installation.png",
            title: "Installation",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />
        <GridElement
          context={{
            imagePath: "/images/fix_issue.png",
            title: "Fix Issues",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />
        <GridElement
          context={{
            imagePath: "/images/marketing.png",
            title: "Digital Marketing",
            para: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          }}
        />
      </Grid>
    </Container>
  );
}
