import styled from "styled-components";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const Card = styled.div`
  width: 350px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: var(--shadow-card);
  padding: calc(385px - 309px) calc(350px - 290px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xxl-card);
  justify-content: center;
`;

const ProfileImg = styled.div`
  width: 120px;
  height: 120px;
  border: 4px solid var(--green-color);
  flex-shrink: 0;
  border-radius: 100px;
  background: url(/images/ratingProfile1.png), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Description = styled.div`
  width: 290px;
  color: var(--Dark-Dark-03, #7a7792);
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-desc);
  line-height: 26px; /* 162.5% */
`;

const Name = styled.div`
  color: var(--green-color);
  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  font-size: 2rem;
  font-weight: bold;
  line-height: 26px; /* 144.444% */
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background: var(--green-color);
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
`;

export default function RatingCard(props) {
  const { id, name, rating, comment, profileImg } = props.context;
  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    // Render full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} color="#FFD700" />);
    }

    // Render half star if applicable
    if (hasHalfStar) {
      stars.push(<FaStarHalfAlt key={fullStars} color="#FFD700" />);
    }

    // Fill the rest with empty stars
    const emptyStarsCount = 5 - stars.length;
    for (let i = 0; i < emptyStarsCount; i++) {
      stars.push(<FaStar key={fullStars + i + 1} color="#CCCCCC" />);
    }

    return stars;
  };

  return (
    <Card key={id}>
      <ProfileImg img={profileImg}>
        <Circle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
          >
            <path
              d="M4.1607 6.32548C3.84052 6.2333 3.52034 6.18652 3.20888 6.18652C2.72793 6.18652 2.32661 6.29643 2.01542 6.43103C2.31542 5.33274 3.03611 3.43767 4.47175 3.22425C4.6047 3.20448 4.71366 3.10834 4.74993 2.97893L5.06371 1.85664C5.09016 1.76173 5.07448 1.66014 5.02034 1.57778C4.96621 1.49541 4.87921 1.44046 4.78171 1.42709C4.67575 1.41264 4.56775 1.40527 4.4607 1.40527C2.73747 1.40527 1.03087 3.20393 0.310735 5.77933C-0.111993 7.29026 -0.235948 9.56184 0.805328 10.9916C1.38801 11.7917 2.23811 12.2189 3.33202 12.2616C3.33652 12.2617 3.34088 12.2619 3.34538 12.2619C4.69511 12.2619 5.89198 11.3529 6.25607 10.0515C6.47357 9.27356 6.37526 8.45769 5.97898 7.75363C5.58694 7.05749 4.94125 6.55007 4.1607 6.32548Z"
              fill="white"
            />
            <path
              d="M12.6096 7.75377C12.2176 7.05749 11.5719 6.55007 10.7913 6.32548C10.4712 6.23329 10.151 6.18652 9.83966 6.18652C9.3587 6.18652 8.95725 6.29643 8.64606 6.43103C8.94606 5.33274 9.66675 3.43767 11.1025 3.22425C11.2355 3.20448 11.3443 3.10834 11.3807 2.97893L11.6945 1.85664C11.7209 1.76173 11.7053 1.66014 11.6511 1.57778C11.5971 1.49541 11.5101 1.44046 11.4125 1.42709C11.3067 1.41264 11.1987 1.40527 11.0915 1.40527C9.36825 1.40527 7.66165 3.20393 6.94137 5.77933C6.51878 7.29026 6.39483 9.56184 7.43624 10.9919C8.01879 11.7918 8.86902 12.2192 9.96279 12.2617C9.96729 12.2619 9.97166 12.262 9.97629 12.262C11.3259 12.262 12.5229 11.353 12.887 10.0517C13.1042 9.2737 13.0058 8.45769 12.6096 7.75377Z"
              fill="white"
            />
          </svg>
        </Circle>
      </ProfileImg>
      <Description>{comment}</Description>
      <Name>{name}</Name>
      <Rating>
        <span>{rating}</span> <span>{renderStars(rating)}</span>
      </Rating>
    </Card>
  );
}
