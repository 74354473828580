const ratingCardData = [
    {
      id: 1,
      name: "John Doe",
      rating: 4.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
    {
      id: 2,
      name: "John Doe2",
      rating: 4.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
    {
      id: 3,
      name: "John Doe3",
      rating: 4.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
    {
      id: 4,
      name: "John Doe",
      rating: 3.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
    {
      id: 5,
      name: "John Doe",
      rating: 4.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
    {
      id: 6,
      name: "John Doe",
      rating: 4.5,
      comment:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      profileImg: "/images/ratingProfile1.png",
    },
  ];

export default ratingCardData;