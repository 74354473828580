import styled from "styled-components";

const Container = styled.div`
  padding: var(--padding-xxl-container);
  background-color: var(--about-bg);
  width: 100vw;
  @media only screen and (max-width: 1800px) {
    padding: var(--padding-xl-container);
  }
`;

const Wrapper = styled.div`
  padding: var(--padding-xxl-card);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border-radius: var(--border-radius-card);
`;

const ImgBox = styled.div`
  position: relative;
  width: 35%;
  height: 38rem;
  background-image: url("/images/aboutImg.png");
  background-size: cover;
  background-position: center;
  z-index: 2;
  border-radius: 10px;
  @media only screen and (max-width: 1600px) {
    height: 25rem;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  gap: 2.5rem; /* Converted from 40px */
  @media only screen and (max-width: 1600px) {
    gap: 1rem; /* Converted from 40px */
  }

  p {
    color: var(--Text-Text-2, #434c56);
    font-family: Poppins;
    font-size: 1rem; /* Converted from 18px */
    font-style: normal;
    font-weight: bold;
    letter-spacing: var(--letter-spacing-desc);
    line-height: 1.67; /* Converted from 30px */
    @media only screen and (max-width: 1600px) {
      font-size: 0.8rem; /* Converted from 18px */
    }
  }
`;

const MiniTitle = styled.div`
  width: 8.6875rem; /* Converted from 138.302px */
  color: var(--green-color);
  font-family: Work Sans;
  font-size: 0.875rem; /* Converted from 14px */
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini); /* Converted from 1.26px */
  text-transform: uppercase;
`;

const Title = styled.div`
  color: var(--Text-Text-1, #283039);
  font-family: Work Sans;
  font-size: 4rem; /* Converted from 48px */
  font-style: normal;
  font-weight: 400;
  line-height: 4rem; /* Converted from 54px */
  @media only screen and (max-width: 1600px) {
    font-size: 2.5rem; /* Converted from 48px */
    font-weight: bold;
    line-height: 2.5rem; /* Converted from 54px */;
  }

  span:nth-child(1) {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 4rem; /* Converted from 48px */
    font-style: normal;
    font-weight: 900;
    line-height: 3.375rem; /* Converted from 54px */
    @media only screen and (max-width: 1600px) {
      font-size: 2.5rem; /* Converted from 48px */
    }
  }
`;

const OptionBox = styled.div``;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem; /* Converted from 10px */

  span:nth-child(1) {
    width: 1.25rem; /* Converted from 20px */
    height: 1.25rem; /* Converted from 20px */
  }

  span:nth-child(2) {
    color: var(--Text-Text-2, #434c56);
    font-family: Poppins;
    font-weight: bold;
    font-size: 1rem; /* Converted from 16px */
    font-style: normal;
    line-height: 1.75; /* Converted from 28px */
    letter-spacing: var(--letter-spacing-desc);
    @media only screen and (max-width: 1600px) {
      font-size: 0.8rem;
    }
  }
`;

export default function About() {
  return (
    <Container id="about">
      <Wrapper>
        <ImgBox />
        <TextBox>
          <MiniTitle>WHO WE ARE</MiniTitle>
          <Title>
            We develop innovative and{" "}
            <span>creative products and services</span>
          </Title>
          <p>
            At Easysoftcode, we develop innovative and creative products and
            services that provide total communication and information solutions.
            Among of services, web design and development, tailor made
            applications, ERPs, CRMs, e-commerce solutions, business-to-business
            applications, business-to-client applications.
          </p>
          <OptionBox>
            <Option>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M9.23027 17.7524C9.17722 17.7524 9.12474 17.7414 9.07613 17.7202C9.02753 17.6989 8.98385 17.6678 8.94785 17.6288L1.33296 9.39173C1.2822 9.33682 1.24855 9.2683 1.23612 9.19455C1.2237 9.12081 1.23304 9.04504 1.26301 8.97653C1.29298 8.90801 1.34227 8.84972 1.40486 8.80878C1.46744 8.76785 1.5406 8.74604 1.61538 8.74604H5.28077C5.3358 8.74605 5.39019 8.75786 5.44027 8.78068C5.49035 8.80349 5.53496 8.83679 5.57108 8.87831L8.116 11.8062C8.39104 11.2182 8.92346 10.2393 9.85777 9.04646C11.239 7.283 13.8082 4.6895 18.2037 2.34827C18.2886 2.30303 18.3875 2.29129 18.4807 2.31536C18.5739 2.33944 18.6546 2.39758 18.7071 2.47832C18.7595 2.55905 18.7797 2.6565 18.7638 2.75142C18.7478 2.84635 18.6969 2.93185 18.621 2.99104C18.6042 3.00416 16.9095 4.33877 14.959 6.78335C13.1639 9.03296 10.7777 12.7114 9.6035 17.4603C9.58287 17.5437 9.5349 17.6178 9.46723 17.6708C9.39956 17.7238 9.3161 17.7526 9.23015 17.7526L9.23027 17.7524Z"
                    fill="#02B591"
                  />
                </svg>
              </span>
              <span>We accept crypto currencies as payment</span>
            </Option>
            <Option>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M9.23027 17.7524C9.17722 17.7524 9.12474 17.7414 9.07613 17.7202C9.02753 17.6989 8.98385 17.6678 8.94785 17.6288L1.33296 9.39173C1.2822 9.33682 1.24855 9.2683 1.23612 9.19455C1.2237 9.12081 1.23304 9.04504 1.26301 8.97653C1.29298 8.90801 1.34227 8.84972 1.40486 8.80878C1.46744 8.76785 1.5406 8.74604 1.61538 8.74604H5.28077C5.3358 8.74605 5.39019 8.75786 5.44027 8.78068C5.49035 8.80349 5.53496 8.83679 5.57108 8.87831L8.116 11.8062C8.39104 11.2182 8.92346 10.2393 9.85777 9.04646C11.239 7.283 13.8082 4.6895 18.2037 2.34827C18.2886 2.30303 18.3875 2.29129 18.4807 2.31536C18.5739 2.33944 18.6546 2.39758 18.7071 2.47832C18.7595 2.55905 18.7797 2.6565 18.7638 2.75142C18.7478 2.84635 18.6969 2.93185 18.621 2.99104C18.6042 3.00416 16.9095 4.33877 14.959 6.78335C13.1639 9.03296 10.7777 12.7114 9.6035 17.4603C9.58287 17.5437 9.5349 17.6178 9.46723 17.6708C9.39956 17.7238 9.3161 17.7526 9.23015 17.7526L9.23027 17.7524Z"
                    fill="#02B591"
                  />
                </svg>
              </span>
              <span>No Long Term Contracts</span>
            </Option>
            <Option>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M9.23027 17.7524C9.17722 17.7524 9.12474 17.7414 9.07613 17.7202C9.02753 17.6989 8.98385 17.6678 8.94785 17.6288L1.33296 9.39173C1.2822 9.33682 1.24855 9.2683 1.23612 9.19455C1.2237 9.12081 1.23304 9.04504 1.26301 8.97653C1.29298 8.90801 1.34227 8.84972 1.40486 8.80878C1.46744 8.76785 1.5406 8.74604 1.61538 8.74604H5.28077C5.3358 8.74605 5.39019 8.75786 5.44027 8.78068C5.49035 8.80349 5.53496 8.83679 5.57108 8.87831L8.116 11.8062C8.39104 11.2182 8.92346 10.2393 9.85777 9.04646C11.239 7.283 13.8082 4.6895 18.2037 2.34827C18.2886 2.30303 18.3875 2.29129 18.4807 2.31536C18.5739 2.33944 18.6546 2.39758 18.7071 2.47832C18.7595 2.55905 18.7797 2.6565 18.7638 2.75142C18.7478 2.84635 18.6969 2.93185 18.621 2.99104C18.6042 3.00416 16.9095 4.33877 14.959 6.78335C13.1639 9.03296 10.7777 12.7114 9.6035 17.4603C9.58287 17.5437 9.5349 17.6178 9.46723 17.6708C9.39956 17.7238 9.3161 17.7526 9.23015 17.7526L9.23027 17.7524Z"
                    fill="#02B591"
                  />
                </svg>
              </span>
              <span>See results within 3 months</span>
            </Option>
          </OptionBox>
        </TextBox>
      </Wrapper>
    </Container>
  );
}
