import React from "react";
import Home from "../components/home/Home";
import CompanysLogo from "../components/companyLogo/CompanysLogo";
import About from "../components/about/About";
import Demo from "../components/demo/Demo";
import PricingPlan from "../components/pricing/PricingPlan";
import Portfolio from "../components/portfolio/Portfolio";
import Services from "../components/service/Services";
import Testimonials from "../components/testimonial/Testimonials";
import Business from "../components/business/Business";
import Team from "../components/team/Team";
import NewsAndBlog from "../components/news_and_blog/NewsAndBlog";
import Footer from "../components/footer/Footer";

export default function Index() {
  return (
    <>
      <Home />
      <CompanysLogo />
      <About />
      <Services />
      <Demo />
      <PricingPlan />
      <Portfolio />
      <Business />
      <Testimonials />
      <Team />
      <NewsAndBlog />
      <Footer />
    </>
  );
}
