import styled from "styled-components";
import TeamCard from "./TeamCard";

const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: var(--padding-xxl-container);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xxl-container);
  /* background-color: var(--team-bg); */
  background-color: var(--team-bg);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  }
`;

const TextSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-text);
`;

const MiniTitle = styled.h3`
  color: var(--green-deep);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: var(--letter-spacing-mini);
  text-transform: uppercase;
`;

const Title = styled.h1`
  color: var(--Dark-Dark-02, #3b3663);
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 54px;
  }
`;

const Description = styled.p`
  width: 540px;
  color: var(--Dark-Dark-03, #7a7792);
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-desc);
  line-height: 28px; /* 155.556% */
`;

const CardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gap-xxl-card);
`;

export default function Team() {
  return (
    <Container id="team">
      <TextSection>
        <MiniTitle>Team</MiniTitle>
        <Title>
          Our Hard <span>Working Team</span>
        </Title>
        <Description>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </Description>
      </TextSection>
      <CardSection>
        <TeamCard />
        <TeamCard />
        <TeamCard />
        <TeamCard />
      </CardSection>
    </Container>
  );
}
