import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 853.333px;
  position: relative;
`;

const ImgBox1 = styled.div`
  width: 49%;
  height: 472.793px;
  flex-shrink: 0;
  border-radius: 18.45px;
  background: #fff;
  box-shadow: var(--shadow-card);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid white;
  background-image: url("/images/portfolio-img-1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImgBox2 = styled.div`
  width: 49%;
  height: 345.946px;
  flex-shrink: 0;
  border-radius: 18.45px;
  background: #fff;
  box-shadow: var(--shadow-card);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border: 10px solid white;
  background-image: url("/images/portfolio-img-2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImgBox3 = styled.div`
  width: 49%;
  height: 345.946px;
  flex-shrink: 0;
  border-radius: 18.45px;
  background: #fff;
  box-shadow: var(--shadow-card);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 10px solid white;
  background-image: url("/images/portfolio-img-3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImgBox4 = styled.div`
  width: 49%;
  height: 472.793px;
  flex-shrink: 0;
  border-radius: 18.45px;
  background: #fff;
  box-shadow: var(--shadow-card);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 10px solid white;
  background-image: url("/images/portfolio-img-4.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Box = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 16.144px;
  background: linear-gradient(
    180deg,
    var(--green-deep) 0%,
    rgba(59, 54, 99, 0.6) 100%
  );
`;

const Btn = styled.button`
  display: inline-flex;
  padding: 19.604px 23.063px;
  align-items: flex-start;
  gap: 11.532px;
  border-radius: 13.838px;
  background: var(--white, #fff);
  border: none;
  position: absolute;
  cursor: pointer;
  font-weight: bold;
`;

const Icon = styled.div`
  width: 20.757px;
  height: 20.757px;
  background-image: url("/images/export.png");
`;

const Title = styled.p`
  color: var(--white, #fff);
  font-family: Work Sans;
  font-size: 27.676px;
  font-style: normal;
  font-weight: 700;
  line-height: 34.595px; /* 125% */
  border-left: 5px solid orange;
  padding-left: 10px;
  position: absolute;
  bottom: 50px;
  left: 50px;
`;

export default function PortfolioDemo() {
  const handleMouseEnter = (e, id) => {
    document.getElementById(id).style.display = "flex";
  };

  const handleMouseLeave = (e, id) => {
    document.getElementById(id).style.display = "none";
  };

  return (
    <Container>
      <ImgBox1
        onMouseEnter={(e) => {
          handleMouseEnter(e, "demo_1");
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e, "demo_1");
        }}
      >
        <Box id="demo_1">
          <Btn>
            View Live Demo <Icon />
          </Btn>
          <Title>Sazone - SaaS Website Design</Title>
        </Box>
      </ImgBox1>
      <ImgBox2
        onMouseEnter={(e) => {
          handleMouseEnter(e, "demo_2");
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e, "demo_2");
        }}
      >
        <Box id="demo_2">
          <Btn>
            View Live Demo <Icon />
          </Btn>
          <Title>Sazone - SaaS Website Design</Title>
        </Box>
      </ImgBox2>
      <ImgBox3
        onMouseEnter={(e) => {
          handleMouseEnter(e, "demo_3");
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e, "demo_3");
        }}
      >
        <Box id="demo_3">
          <Btn>
            View Live Demo <Icon />
          </Btn>
          <Title>Sazone - SaaS Website Design</Title>
        </Box>
      </ImgBox3>
      <ImgBox4
        onMouseEnter={(e) => {
          handleMouseEnter(e, "demo_4");
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e, "demo_4");
        }}
      >
        <Box id="demo_4">
          <Btn>
            View Live Demo <Icon />
          </Btn>
          <Title>Sazone - SaaS Website Design</Title>
        </Box>
      </ImgBox4>
    </Container>
  );
}
