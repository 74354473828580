import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";

const Nav = styled.nav`
  display: flex;
  width: 100%;
  height: 5.5rem; /* converted from 88px to rem */
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.scrolled ? "rgb(15, 161, 86, 100%)" : "transparent"};
  position: fixed;
  z-index: 99;
  top: 0;
  @media only screen and (max-width: 1920px) {
    padding: 0 4rem;
  }
`;

const Logo = styled.a`
  img {
    @media only screen and (max-width: 1600px) {
      height: 1.4rem;
      width: auto;
    }
  }
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  gap: 2.5rem; /* converted from 40px to rem */

  li {
    list-style: none;
    .active {
      &::after {
        width: 100% !important;
      }
    }
  }

  a {
    color: white;
    font-size: 1rem; /* converted from 16px to rem */
    font-style: normal;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: normal;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    @media only screen and (max-width: 1600px) {
      font-size: 0.7rem;
    }
  }

  a:after {
    content: "";
    position: absolute;
    bottom: -0.125rem; /* converted from 2px to rem */
    left: 0;
    height: 0.0625rem; /* converted from 1px to rem */
    width: 0%;
    background: var(--Dark-Dark-07, #fbfaff);
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  a:hover::after {
    width: 100%;
  }
`;

const GetStart = styled.a`
  display: flex;
  padding: 0.625rem 1.25rem; /* converted from 10px 20px to rem */
  justify-content: center;
  align-items: center;
  gap: 0.625rem; /* converted from 10px to rem */
  font-size: 1rem; /* converted from 16px to rem */
  font-style: normal;
  font-family: "Poppins";
  font-weight: bold;
  letter-spacing: 1.3px;
  line-height: 1.625rem; /* converted from 26px to rem */
  border-radius: 0.375rem; /* converted from 6px to rem */
  background: var(--Dark-Dark-07, #fbfaff);
  color: var(--Primary-main, #4154f1);
  cursor: pointer;
  @media only screen and (max-width: 1600px) {
    padding: 0.4rem 1rem;
    font-size: 0.7rem;
  }
`;

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav scrolled={scrolled}>
      <Logo href="#">
        <img src="/images/Logo.png" alt="easy soft code logo" />
      </Logo>
      <Menu>
        <li>
          <ScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Home
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Services
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="products"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Products
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="pricing"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Pricing
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="portfolio"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Portfolio
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="testimonials"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Testimonials
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="team"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Team
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="blog"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Blog
          </ScrollLink>
        </li>
        <li>
          <a href="/">Contact</a>
        </li>
      </Menu>
      <ScrollLink
        to="about"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <GetStart>Get Started</GetStart>
      </ScrollLink>
    </Nav>
  );
}
