import styled from "styled-components";

const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: var(--padding-xxl-card);
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card);
`;

const TextContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  div:nth-child(1) {
    color: var(--green-color);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: var(--letter-spacing-mini);
    line-height: 28px; /* 155.556% */
  }
  div:nth-child(2) {
    color: var(--Dark-Dark-01, #191638);
    font-family: Work Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133.333% */
  }
`;

const ImageContainer = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Img = styled.img`
  width: 407px;
  height: 351px;
  background: url(${(props) => props.imagePath}),
    lightgray 50% / cover no-repeat;
`;

const ButtonBox = styled.div`
  width: 159.586px;
  height: 46px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Rectangle = styled.div`
  width: 42px;
  height: 34px;
  flex-shrink: 0;
  background: var(--Primary-Primary-shad-light, #f0eeff);
`;

const Btn = styled.button`
  flex-shrink: 0;
  border-radius: 6px;
  color: var(--Primary-main, #4154f1);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  background: none;
  cursor: pointer;
`;

const Arrow = styled.div`
  width: 26.787px;
  height: 26.787px;
  transform: rotate(-46.549deg);
  flex-shrink: 0;
  position: absolute;
  left: 0;
`;

export default function DemoCard(props) {
  const { title, miniTitle, imagePath } = props.context;

  return (
    <Card>
      <TextContainer>
        <div>{miniTitle}</div>
        <div>{title}</div>
      </TextContainer>
      <ImageContainer>
        <Img imagePath={imagePath} />
        <ButtonBox>
          <Rectangle></Rectangle>
          <Btn>Learn More</Btn>
          <Arrow>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
            >
              <path
                d="M26.3086 12.9594L26.5966 23.61C26.5953 23.7726 26.5308 23.9284 26.4169 24.0445C26.303 24.1606 26.1484 24.228 25.9858 24.2324C25.8232 24.2368 25.6652 24.1779 25.5452 24.0681C25.4251 23.9583 25.3523 23.8062 25.3422 23.6439L25.0944 14.5088L13.4719 26.7775C13.3573 26.8984 13.1995 26.9688 13.0331 26.9733C12.8666 26.9778 12.7052 26.916 12.5843 26.8015C12.4634 26.687 12.393 26.5291 12.3885 26.3627C12.384 26.1962 12.4458 26.0348 12.5603 25.9139L24.1829 13.6453L15.0478 13.8916C14.8851 13.8902 14.7294 13.8258 14.6132 13.7119C14.4971 13.5979 14.4298 13.4434 14.4254 13.2808C14.421 13.1181 14.4799 12.9602 14.5897 12.8401C14.6995 12.7201 14.8515 12.6473 15.0139 12.6372L25.6644 12.3492C25.8308 12.3448 25.992 12.4066 26.1128 12.521C26.2336 12.6354 26.304 12.7931 26.3086 12.9594Z"
                fill="#4154F1"
              />
            </svg>
          </Arrow>
        </ButtonBox>
      </ImageContainer>
    </Card>
  );
}
