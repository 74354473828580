import Navbar from "../components/navbar/Navbar";
import { Outlet } from "react-router-dom"
import styled  from "styled-components"


export default function Layout() {
  return (
    <Container>
      <Navbar />
      <Outlet />
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;