import styled from "styled-components";
import { FaCalendarWeek, FaUser, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Card = styled.div`
  width: 399.777px;
  height: 528.848px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const CardImg = styled.div`
  background-image: url("/images/BlogCardImg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 399.777px;
  height: 251.288px;
  flex-shrink: 0;
  /* border-radius: 18.276px 18.276px 0px 0px; */
`;

const Details = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DateAndUserDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 9.138px;
  span {
    color: var(--Dark-dark_2, #3b3663);
    font-family: Work Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
`;

const Title = styled.div`
  width: 342.666px;
  color: var(--Dark-Dark-01, #191638);

  /* H5 */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
`;

const Description = styled.div`
  width: 342.666px;
  color: var(--Dark-Dark-03, #7a7792);
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
`;

const ReadMore = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    color: var(--Primary-main, #4154f1);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    text-decoration: none;
  }
`;

export default function BlogCard() {
  return (
    <Card>
      <CardImg />
      <Details>
        <DateAndUserDetails>
          <Group>
            <FaCalendarWeek color="darkgreen" fontSize={18} />
            <span>Feb 15, 2024</span>
          </Group>
          <Group>
            <FaUser color="darkgreen" fontSize={18} />
            <span>Ronald Richards</span>
          </Group>
        </DateAndUserDetails>
        <Title>
          Website Development Process 101 | A Guide to Pixel-Perfect
        </Title>
        <Description>
          Consectetur adipiscing elit, sed do ut ei u smod tempor incididunt...
        </Description>
        <ReadMore>
          <Link to="/">Read more</Link>
          <span>
            <FaArrowRight fontSize={18} color="#4154f1" />
          </span>
        </ReadMore>
      </Details>
    </Card>
  );
}
