import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  padding: var(--padding-xxl-container);
  align-items: center;
  background: var(--business-bg);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-container);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: var(--padding-xxl-card);
  background-color: white;
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card);
  @media only screen and (max-width: 1600px) {
    padding: var(--padding-xl-card);
  }
`;

const TextContainer = styled.div`
  width: 50%;
  height: 438.667px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  @media only screen and (max-width: 1600px) {
    width: 60%;
  }
`;

const Group1 = styled.div`
  height: 292.667px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

const Title = styled.div`
  flex-shrink: 0;
  color: black;
  font-family: "Poppins";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  @media only screen and (max-width: 1600px) {
    font-size: 2.3rem;
  }
  span {
    color: var(--green-deep);
    font-family: Work Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 54px; /* 112.5% */
    @media only screen and (max-width: 1600px) {
      font-size: 2.3  rem;
    }
  }
`;

const Description = styled.p`
  color: black;
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-desc);
  line-height: 26px; /* 162.5% */
  opacity: 0.95;
  @media only screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;

const OptionContainer = styled.div`
  width: 100%;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-top: 20px;
`;

const OptionGroup = styled.div`
  height: 26px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  span:nth-child(1) {
    width: 14.488px;
    height: 14.134px;
    flex-shrink: 0;
  }
  span:nth-child(2) {
    color: black;
    font-family: "Poppins";
    font-size: .9rem;
    font-style: normal;
    font-weight: bold;
    line-height: 26px; /* 162.5% */
    @media only screen and (max-width: 1600px) {
      font-size: 0.8rem;
      letter-spacing: 1.3px;
      font-family: "Poppins";
    }
  }
`;

const Group2 = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Group3 = styled.div`
  width: 139px;
  flex-shrink: 0;
  h1 {
    color: var(--green-deep);
    text-align: center;
    font-family: "Poppins";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 128.571% */
    @media only screen and (max-width: 1600px) {
      font-size: 3rem;
    }
  }

  div {
    color: var(--light-text-color, black);
    text-align: center;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: var(--letter-spacing-mini);
    line-height: 26px; /* 162.5% */
    @media only screen and (max-width: 1600px) {
      font-size: 0.8rem;
    }
  }
`;

const ImageContainer = styled.div`
  width: 45%;
  height: 453px;
  background-image: url("/images/businessSec.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0px 54px 50px 0px rgba(67, 67, 68, 0.07);
  position: relative;
  @media only screen and (max-width: 1600px) {
    width: 38%;
  }
`;

export default function Business() {
  const optionIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M13.8557 0.274762C13.2019 -0.114007 12.4727 0.635763 12.0452 1.08007C11.0645 2.1353 10.2347 3.35715 9.3043 4.46792C8.27333 5.68977 7.31779 6.91162 6.26167 8.10574C5.65817 8.7722 5.00438 9.49421 4.60205 10.3273C3.69681 9.35531 2.91729 8.30008 1.91146 7.43928C1.18224 6.82836 -0.024759 6.38405 0.00038672 7.85582C0.0506782 9.77194 1.58457 11.8269 2.71612 13.132C3.19389 13.6874 3.82254 14.2705 4.55176 14.2983C5.43186 14.3538 6.33711 13.1875 6.86517 12.5488C7.7956 11.4381 8.54997 10.1884 9.40489 9.0499C10.5113 7.55036 11.6429 6.07854 12.7241 4.55123C13.4031 3.60707 15.5404 1.27441 13.8557 0.274762ZM1.10676 7.74474C1.08161 7.74474 1.05647 7.74474 1.00617 7.77247C0.905592 7.74474 0.830154 7.71693 0.729572 7.66139C0.805009 7.60585 0.930737 7.63362 1.10676 7.74474Z"
        fill="#2FCB8D"
      />
    </svg>
  );
  return (
    <Container>
      <Wrapper>
        <TextContainer>
          <Group1>
            <Title>
              Keep Your Business <span>Safe & Ensure High</span>
            </Title>
            <Description>
              A business consulting agency is involved in the planning,
              implementation, and education of businesses. We work directly with
              business owners on developing a business plan.
            </Description>
            <OptionContainer>
              <OptionGroup>
                <span>{optionIcon}</span> <span>Constant Improvement</span>
              </OptionGroup>
              <OptionGroup>
                <span>{optionIcon}</span> <span>Best Quality You Can Get</span>
              </OptionGroup>
              <OptionGroup>
                <span>{optionIcon}</span> <span>Commitment to Customers</span>
              </OptionGroup>
              <OptionGroup>
                <span>{optionIcon}</span>
                <span>30 Days Money Back Warranty</span>
              </OptionGroup>
            </OptionContainer>
          </Group1>
          <Group2>
            <Group3>
              <h1>120</h1>
              <div>Happy Customers</div>
            </Group3>
            <Group3>
              <h1>150</h1>
              <div>Projects</div>
            </Group3>
            <Group3>
              <h1>04</h1>
              <div>Glorious Year</div>
            </Group3>
          </Group2>
        </TextContainer>
        <ImageContainer></ImageContainer>
      </Wrapper>
    </Container>
  );
}
